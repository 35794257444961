<template>
	<main class="page-container">
		<section class="detail-section" data-fill="true">
			<!-- 지원사업 LNB -->
			<lnb-support-biz-intrd className="" :openVisible="true" />
			<!-- //지원사업 LNB -->
			<div class="container">
				<div class="page-header">
					<h3 class="page-header-title">
						<router-link :to="`/supportbiz/intrd/${intrdId}`" class="button-link-home">
							<span class="sr-only">홈으로 이동</span>
						</router-link>
						{{exmInfo.intrdTit}}
					</h3>
				</div>
				<!-- 제목 -->
				<div class="detail-section-header">
					<p class="detail-section-header__sub" v-if="exmInfo.suptExmNm">[{{exmInfo.suptExmNm}}]</p>
					<div class="detail-section-header__title">
						<p class="title">{{exmInfo.exmTit}}</p>
						<span class="date"></span>
					</div>
				</div>
				<!-- // 제목 -->

				<div class="post-view-body">
					<div class="gachi-view" v-html="exmInfo.exmCn"></div>
				</div>
				<!-- 
				<div class="vod-wrap" v-html="exmInfo.exmCn"></div> 
				-->

				<div class="detail-section-footer">
					<div class="buttons">
						<router-link v-if="exmInfo.preId && exmInfo.preId > 0" :to="`/supportbiz/intrd/${intrdId}/exm/${!exmInfo.preId || exmInfo.preId == 0 ? exmId : exmInfo.preId}?${queryString}`" class="button-default is-large is-rounded is-gray">이전</router-link>
						<router-link :to="`/supportbiz/intrd/${intrdId}/exms?${queryString}`" class="button-default is-large is-rounded is-secondary">목록</router-link>
						<router-link v-if="exmInfo.nextId && exmInfo.nextId > 0" :to="`/supportbiz/intrd/${intrdId}/exm/${!exmInfo.nextId || exmInfo.nextId == 0 ? exmId : exmInfo.nextId}?${queryString}`" class="button-default is-large is-rounded is-gray">다음</router-link>
					</div>
				</div>
			</div>
		</section>
	</main>
</template>

<script>
import { mapGetters } from 'vuex';
import { ACT_GET_SPRT_BIZ_INTRD_EXM } from '@/store/_act_consts';
import LnbSupportBizIntrd from '@/components/support/LnbSupportBizIntrd';
import { getItems, lengthCheck, queryToValue } from '@/assets/js/utils';
const jsonp = require('jsonp');

export default {
	name: 'supportBizIntrdExmDetail',
	components: { LnbSupportBizIntrd },
	data: () => ({
		exmId: 0,
		intrdId: 0,
		suptExmCd: '10',
		pageNo: 1,
		sortCd: '',
		exmInfo: {}
	}),
	computed: {
		...mapGetters('auth', ['isAuth', 'session', 'profile', 'creatorModal']),
		queryString: function () {
			return `suptExmCd=${this.suptExmCd}&pageNo=${this.pageNo}&sortCd=${this.sortCd ?? ''}`
		}
	},
	watch:{
		$route(to){
			this.init();
		}
	},
	mounted() {},
	created() {
		this.init();
	},
	methods: {
		init (){
			this.exmInfo = {};
		
			this.exmId = queryToValue(this.$route.params.exmId, true, 0);
			this.intrdId = queryToValue(this.$route.params.intrdId, true, 0);
			this.suptExmCd = queryToValue(this.$route.query.suptExmCd, false, '10');
			this.pageNo = queryToValue(this.$route.query.pageNo, true, 1);
			this.sortCd = queryToValue(this.$route.query.sortCd, false, '');
			this.getData();
		},
		getData(){	
			this.$store
				.dispatch(`support/${ACT_GET_SPRT_BIZ_INTRD_EXM}`, {
					exmId : this.exmId,
					params: {
						intrdId: this.intrdId,
						suptExmCd: this.suptExmCd,
						sortCd: this.sortCd
					}
				})
				.then((res) => {
					if (lengthCheck(res)) {
						this.exmInfo = getItems(res)[0];
					} 
				})
				.catch((e) => {
					console.error(e);
				});
		}
	},
};
</script>
